import { TypedEvent } from "@faro-lotv/foundation";
import {
	Box3,
	BufferGeometry,
	DoubleSide,
	FrontSide,
	Intersection,
	Matrix4,
	Mesh,
	Object3D,
	Plane,
	Raycaster,
	Texture,
	Vector3,
} from "three";
import { CadModelIDsMaterial, CadModelMaterial, MAX_NUMBER_OF_COLOR_TEXTURES } from "../Materials/CadModelMaterial";
import { CadBasicRenderBuffers, CadNode, CadNodeMaterial } from "./CadModelDataStructures";
import {
	TomographicMaterialBackup,
	assignMaterial,
	checkDataSanity,
	computeMainGeometry,
	createCadNodes,
	createNewGeometry,
	raycastCadNode,
	restoreMaterial,
	setTomographicMaterial,
	sortMeshes,
} from "./CadModelPrivateUtils";
import { CadRenderingMode, ICadModel } from "./ICadModel";

/** A placeholder texture used to fill missing textures in the material */
const EMPTY_TEXTURE = new Texture();

/**
 * A class with capabilities to render complex CAD scene graphs with very high performance.
 *
 * It has the "Basic" suffix because it does not exploit the WebGL Multidraw extension to render the CAD model.
 * Therefore, it can be used on devices that do not support multidraw (e.g. the Firefox browser) or that offer
 * only software support for multidraw (e.g. Intel GPUs)
 */
export class CadModel extends Mesh<BufferGeometry, CadModelMaterial | CadModelIDsMaterial> implements ICadModel {
	/** Material to be used to render the model */
	#defaultMaterial = new CadModelMaterial();
	/** Material to be used to render the cad part IDs */
	#partIDsMaterial = new CadModelIDsMaterial();
	/** The root of the CAD assembly graph. */
	#root: Object3D;
	/** List of the CAD nodes */
	#cadNodes = new Array<CadNode>();
	/** The rendering mode of this CAD model */
	#renderingMode = CadRenderingMode.OpaqueScene;
	/** All color textures of this model */
	#colorTextures = new Array<Texture>();
	/** Render data */
	#renderBuffers = new CadBasicRenderBuffers();
	/** First index of transparent parts in the geometry buffer */
	#firstTransparentIndex = -1;
	/** Number of transparent indices */
	#transparentIndicesCount = -1;
	/** Event emitted when the list of visible objects changed */
	#visibleObjectsChanged = new TypedEvent<void>();
	/** Map from ObjectID to indices of CadNodes belong to the Object */
	#objectIdToNodeIds = new Map<number, number[]>();
	/** Backup material properties when set to tomographic mode, used to restore the normal material properties */
	#tomographicBackup: TomographicMaterialBackup | undefined = undefined;

	/**
	 * Constructs a CADModel object initializing all the data structures for rendering with webgl_multi_draw
	 *
	 * @param root the root object of the GLTF scene.
	 */
	constructor(root: Object3D) {
		super();
		this.material = this.#defaultMaterial;
		this.#root = root;
		this.#computeCadNodes();
	}

	/**
	 * Initializes the material that is the shader used for rendering, according to whether the
	 * model is textured or not.
	 */
	#initializeShader(): void {
		if (this.isTextured()) {
			// If the material has less than MAX_NUMBER_OF_COLOR_TEXTURES, fill the remaining
			// with an empty texture
			for (let i = this.#colorTextures.length; i < MAX_NUMBER_OF_COLOR_TEXTURES; ++i) {
				this.#colorTextures[i] = EMPTY_TEXTURE;
			}

			this.#defaultMaterial.isTextured = true;
			this.#defaultMaterial.uniforms.colorTextures.value = this.#colorTextures;
			this.material = this.#defaultMaterial;
		}
	}

	/** Inits the data textures with all Cad parts' poses and materials. */
	#initDataTextures(): void {
		// allocate data buffers for rendering
		this.#renderBuffers.allocateBuffers(this.#cadNodes.length);

		// fill the buffers
		for (let n = 0; n < this.#cadNodes.length; ++n) {
			const node = this.#cadNodes[n];
			this.#renderBuffers.setPose(node, n);
			this.#renderBuffers.setMaterial(node, n);
		}
		this.#renderBuffers.maybeUpdatePosesTexture();
		this.#renderBuffers.maybeUpdateMaterialsTexture();
		this.#defaultMaterial.uniforms.poseTexture.value = this.#renderBuffers.posesDataTexture;
		this.#partIDsMaterial.uniforms.poseTexture.value = this.#renderBuffers.posesDataTexture;
		this.#defaultMaterial.uniforms.materialTexture.value = this.#renderBuffers.materialsDataTexture;
	}

	/**
	 * Initializes all data needed for rendering and management of the single CAD parts
	 */
	#computeCadNodes(): void {
		// Computing the absolute pose matrices of all nodes in the CAD scene graph
		this.#root.updateWorldMatrix(true, true);

		const meshes = sortMeshes(this.#root);
		if (meshes.length === 0) {
			// If the Cad is empty for some reason,
			// the data textures are still allocated so
			// webgl errors are avoided.
			this.#renderBuffers.allocateBuffers(64);
			return;
		}

		// set model position to the center of the first mesh
		meshes[0].getWorldPosition(this.position);

		// Creating all Cad and offset all nodes to be centered at the model position
		createCadNodes(meshes, this.#cadNodes, this.#colorTextures, this.position);

		this.geometry = createNewGeometry(meshes);
		computeMainGeometry(this.geometry, this.#cadNodes, 0, this.#cadNodes.length);
		checkDataSanity(this.geometry);

		// setup transparency index range
		this.#firstTransparentIndex = -1;
		let iCount = 0;
		for (const n of this.#cadNodes) {
			if (n.material.opacity < 1.0 && this.#firstTransparentIndex === -1) {
				this.#firstTransparentIndex = iCount;
			}
			iCount += n.iCount;
		}
		if (this.#firstTransparentIndex === -1) {
			this.#firstTransparentIndex = iCount;
		}
		this.#transparentIndicesCount = iCount - this.#firstTransparentIndex;

		this.#initializeShader();

		this.#initDataTextures();

		this.#setRenderingMode(CadRenderingMode.OpaqueScene);

		this.#computeObjectIdToNodeIdsMap();
	}

	/**
	 * @inheritdoc
	 */
	override onBeforeRender = (): void => {
		this.#renderBuffers.maybeUpdatePosesTexture();
		if (this.#renderingMode !== CadRenderingMode.PartsIDs) {
			this.#renderBuffers.maybeUpdateMaterialsTexture();
		}
	};

	/**
	 *
	 * @returns Whether this CAD model has color textures.
	 */
	isTextured(): boolean {
		return this.geometry.hasAttribute("uv");
	}

	/** @inheritdoc */
	nodesCount(): number {
		return this.#cadNodes.length;
	}

	/** @returns The root of the original CAD scene graph */
	get root(): Object3D {
		return this.#root;
	}

	/**
	 * Returns the name of the n-th node in the CAD scene graph.
	 *
	 * @param {number} n The index of the queried node
	 * @returns {string} The name of node n
	 */
	nodeName(n: number): string {
		return this.#cadNodes[n].name;
	}

	/**
	 * Returns the absolute pose matrix of the n-th node in the CAD scene graph.
	 *
	 * @param {number} n The index of the queried node.
	 * @param {Matrix4} pose if provided by the caller, it will hold the absolute pose matrix
	 * @returns {Matrix4} The absolute pose matrix of the queried node.
	 */
	nodePose(n: number, pose?: Matrix4): Matrix4 {
		// The matrixWorld of a node is relative to the global position of the cad model.
		// the absolute pose matrix = the global position + this.#cadNodes[n].matrixWorld
		const offsetMatrix = (pose ?? new Matrix4()).makeTranslation(this.position.x, this.position.y, this.position.z);
		return offsetMatrix.multiply(this.#cadNodes[n].matrixWorld);
	}

	/**
	 * Sets the absolute pose matrix of node 'n' to pose 'p'.
	 *
	 * @param {number} n The Index of the node to be modified.
	 * @param {Matrix4} p The new pose.
	 */
	setNodePose(n: number, p: Matrix4): void {
		// The matrixWorld of a node is relative to the global position of the cad model.
		// The absolute pose matrix should be offset by the global position to get the matrixWorld of a node
		const cadNode = this.#cadNodes[n];
		cadNode.matrixWorld
			.copy(p)
			.premultiply(new Matrix4().makeTranslation(-this.position.x, -this.position.y, -this.position.z));
		this.#renderBuffers.setPose(cadNode, n);
	}

	/**
	 * Returns the material of the n-th node in the CAD scene graph.
	 *
	 * @param {number} n The index of the queried node.
	 * @returns {CadNodeMaterial} A simple representation of the node's phong material.
	 */
	nodeMaterial(n: number): CadNodeMaterial {
		return this.#cadNodes[n].material;
	}

	/**
	 * Sets the node material of node 'n' to 'mat.
	 *
	 * @param {number} n The Index of the node to be modified.
	 * @param {CadNodeMaterial} mat A simple representation of its phong material.
	 * @param textureId optional number to change the texture ID
	 */
	setNodeMaterial(n: number, mat: CadNodeMaterial, textureId?: number): void {
		const cadNode = this.#cadNodes[n];
		assignMaterial(cadNode.material, mat);
		cadNode.textureId = textureId ?? -1;
		this.#renderBuffers.setMaterial(cadNode, n);
	}

	/**
	 * Returns the ambient color of a specific CAD part.
	 * Shortcut instead of calling cad.nodeMaterial(n).ambient;
	 *
	 * @param n The given CAD part
	 * @returns the ambient color of the CAD part
	 */
	nodeColor(n: number): Vector3 {
		return this.nodeMaterial(n).ambient;
	}

	/**
	 * Sets the ambient and diffuse color of a specific CAD part.
	 * Shortcut for the more complex call 'setNodeMaterial'.
	 *
	 * @param n ID of the CAD part
	 * @param c a triplet with RGB components from 0 to 1
	 */
	setNodeColor(n: number, c: Vector3): void {
		const cadNode = this.#cadNodes[n];
		cadNode.material.ambient.copy(c);
		cadNode.material.diffuse.copy(c);
		this.#renderBuffers.setMaterial(cadNode, n);
	}

	/**
	 * Returns whether node n is visible or not
	 *
	 * @param n The index of the queried node
	 * @returns whether the node n is visible or hidden
	 */
	isNodeVisible(n: number): boolean {
		return this.#cadNodes[n].visible;
	}

	/**
	 * Sets whether node n is visible or not
	 *
	 * @param n Index of the queried node
	 * @param v Whether n should be visible or not
	 */
	setNodeVisible(n: number, v: boolean): void {
		const cadNode = this.#cadNodes[n];
		if (cadNode.visible !== v) {
			cadNode.visible = v;
			this.#renderBuffers.updateVisibilityFlag(cadNode, n);
			this.#visibleObjectsChanged.emit();
		}
	}

	/** @inheritdoc */
	get visibleObjectsChanged(): TypedEvent<void> {
		return this.#visibleObjectsChanged;
	}

	/**
	 * Returns whether node n is highlighted or not
	 *
	 * @param n The index of the queried node
	 * @returns whether node n is highlighted or not
	 */
	isNodeHighlighted(n: number): boolean {
		return this.#cadNodes[n].highlighted;
	}

	/**
	 * Sets whether node n is highlighted or not
	 *
	 * @param n The index of the node
	 * @param v True iff node n should be highlighted.
	 */
	setNodeHighlighted(n: number, v: boolean): void {
		const cadNode = this.#cadNodes[n];
		if (v !== cadNode.highlighted) {
			cadNode.highlighted = v;
			this.#renderBuffers.setMaterial(cadNode, n);
		}
	}

	/** @inheritdoc */
	get highlightingColor(): Vector3 {
		return this.#defaultMaterial.uniforms.highlightingColor.value.clone();
	}

	/** @inheritdoc */
	set highlightingColor(color: Vector3) {
		this.#defaultMaterial.uniforms.highlightingColor.value.copy(color);
	}

	/** @inheritdoc */
	get clippingInLocalTransform(): boolean {
		return this.#defaultMaterial.uniforms.clippingInLocalTransform.value;
	}

	/** @inheritdoc */
	set clippingInLocalTransform(value: boolean) {
		this.#defaultMaterial.uniforms.clippingInLocalTransform.value = value;
	}

	/**
	 * @param n index of the queried node
	 * @returns the original mesh associated to this node
	 */
	nodeOriginalMesh(n: number): Mesh {
		return this.#cadNodes[n].originalMesh;
	}

	/** @inheritdoc */
	nodeBoundingBox(n: number, ret = new Box3()): Box3 {
		const node = this.#cadNodes[n];
		return ret.copy(node.boundingBox).applyMatrix4(node.matrixWorld).applyMatrix4(this.matrixWorld);
	}

	/** @inheritdoc */
	get renderingMode(): CadRenderingMode {
		return this.#renderingMode;
	}

	/** Switches the material of this object to the default material, if needed */
	#switchToDefaultMaterial(): void {
		if (this.material === this.#defaultMaterial) return;
		this.#defaultMaterial.clippingPlanes = this.material.clippingPlanes;
		this.#defaultMaterial.clipping = this.material.clipping;
		this.#defaultMaterial.clipIntersection = this.material.clipIntersection;
		this.material = this.#defaultMaterial;
	}

	/** Switching the material of this object to the part ids material, if needed */
	#switchToPartIDsMaterial(): void {
		if (this.material === this.#partIDsMaterial) return;
		this.#partIDsMaterial.clippingPlanes = this.material.clippingPlanes;
		this.#partIDsMaterial.clipping = this.material.clipping;
		this.#partIDsMaterial.clipIntersection = this.material.clipIntersection;
		this.material = this.#partIDsMaterial;
	}

	/** @inheritdoc */
	set renderingMode(m: CadRenderingMode) {
		if (m === this.#renderingMode) return;
		this.#setRenderingMode(m);
	}

	/**
	 * Set the rendering mode of this object
	 *
	 * 	@param m The new rendering mode
	 */
	#setRenderingMode(m: CadRenderingMode): void {
		this.#renderingMode = m;
		switch (this.#renderingMode) {
			case CadRenderingMode.OpaqueScene:
				this.#switchToDefaultMaterial();
				this.material.transparent = false;
				this.material.depthWrite = true;
				this.material.side = DoubleSide;
				this.geometry.setDrawRange(0, this.#firstTransparentIndex);
				break;
			case CadRenderingMode.TransparentScene:
				this.#switchToDefaultMaterial();
				this.material.side = FrontSide;
				this.material.transparent = true;
				this.material.depthWrite = false;
				this.geometry.setDrawRange(this.#firstTransparentIndex, this.#transparentIndicesCount);
				break;
			case CadRenderingMode.PartsIDs:
				this.#switchToPartIDsMaterial();
				this.material.side = DoubleSide;
				this.geometry.setDrawRange(0, this.#firstTransparentIndex + this.#transparentIndicesCount);
				break;
		}
	}

	/** Disposes all GPU resources used by this object */
	dispose(): void {
		this.geometry.dispose();
		this.#defaultMaterial.dispose();
		this.#partIDsMaterial.dispose();
		this.#renderBuffers.dispose();
	}

	/** @inheritdoc */
	raycastNode(n: number, raycaster: Raycaster, intersections: Array<Intersection<Object3D>>): void {
		raycastCadNode(this, this.#cadNodes[n], raycaster, intersections);
	}

	/**
	 * Computes the map from ObjectID to indices of CadNodes belong to the Object
	 */
	#computeObjectIdToNodeIdsMap(): void {
		this.#objectIdToNodeIds.clear();
		for (let i = 0; i < this.#cadNodes.length; i++) {
			const node = this.#cadNodes[i];
			if (node.objectId === undefined) continue;

			let ids = this.#objectIdToNodeIds.get(node.objectId);
			if (!ids) {
				ids = [];
				this.#objectIdToNodeIds.set(node.objectId, ids);
			}
			ids.push(i);
		}
	}

	/** @inheritdoc */
	get objectIds(): IterableIterator<number> {
		return this.#objectIdToNodeIds.keys();
	}

	/** @inheritdoc */
	drawIdToObjectId(drawId: number): number | undefined {
		if (drawId < 0 || drawId >= this.#cadNodes.length) return undefined;
		return this.#cadNodes[drawId].objectId;
	}

	/** @inheritdoc */
	setObjectVisible(objectId: number, v: boolean): void {
		const ids = this.#objectIdToNodeIds.get(objectId);
		if (!ids) return;

		let changed = false;
		for (const id of ids) {
			const cadNode = this.#cadNodes[id];
			if (cadNode.visible !== v) {
				cadNode.visible = v;
				this.#renderBuffers.updateVisibilityFlag(cadNode, id);
				changed = true;
			}
		}
		if (changed) {
			this.#visibleObjectsChanged.emit();
		}
	}

	/** @inheritdoc */
	isObjectVisible(objectId: number): boolean {
		const ids = this.#objectIdToNodeIds.get(objectId);
		if (!ids || ids.length === 0) return false;
		return this.isNodeVisible(ids[0]);
	}

	/** @inheritdoc */
	setObjectHighlighted(objectId: number, v: boolean): void {
		const ids = this.#objectIdToNodeIds.get(objectId);
		if (!ids) return;

		for (const id of ids) {
			this.setNodeHighlighted(id, v);
		}
	}

	/** @inheritdoc */
	isObjectHighlighted(objectId: number): boolean {
		const ids = this.#objectIdToNodeIds.get(objectId);
		if (!ids || ids.length === 0) return false;
		return this.isNodeHighlighted(ids[0]);
	}

	/** @inheritdoc */
	get clipping(): boolean {
		return this.material.clipping;
	}

	/** @inheritdoc */
	set clipping(v: boolean) {
		this.material.clipping = v;
	}

	/** @inheritdoc */
	set clippingPlanes(planes: Plane[]) {
		this.material.clippingPlanes = planes;
	}

	/** @inheritdoc */
	get clipIntersection(): boolean {
		return this.material.clipIntersection;
	}

	/** @inheritdoc */
	set clipIntersection(v: boolean) {
		this.material.clipIntersection = v;
	}

	/** @inheritdoc */
	setTomographic(tomographic: boolean): void {
		// Make sure CAD model is rendered as opaque scene, where the material
		// is an instance of CadModelMaterial
		this.renderingMode = CadRenderingMode.OpaqueScene;

		if (tomographic) {
			if (this.#tomographicBackup === undefined) {
				this.#tomographicBackup = setTomographicMaterial(this.#defaultMaterial);
			}
		} else if (this.#tomographicBackup) {
			restoreMaterial(this.#defaultMaterial, this.#tomographicBackup);
			this.#tomographicBackup = undefined;
		}
	}
}
