import {
	ColorRepresentation,
	DoubleSide,
	Euler,
	FrontSide,
	Mesh,
	MeshBasicMaterial,
	PlaneGeometry,
	Vector3,
} from "three";

/**
 * Mesh for a single side of the BoxControls box
 */
export class BoxSide extends Mesh {
	/** The default name of the 3D object */
	name = "BoxSide";

	/** Geometry used to render a side of the box */
	static geometry = new PlaneGeometry(1, 1);

	/** The base material of the box side. The parameters are set by #updateMaterial() according to its state. */
	material = new MeshBasicMaterial({ transparent: true, depthWrite: false });

	/**
	 * @param position The position of the box relative to the parent
	 * @param rotation The rotation of the box relative to the parent
	 * @param color The color to use for the box side
	 * @param selectedColor The color to use for a selected box side
	 */
	constructor(
		position: Vector3,
		rotation: Euler,
		private color: ColorRepresentation,
		private selectedColor: ColorRepresentation,
	) {
		super();

		this.geometry = BoxSide.geometry;

		this.#updateMaterial();

		this.position.copy(position);
		this.rotation.copy(rotation);
	}

	#hovered: boolean = false;
	/** whether the current side is hovered */
	set hovered(hovered: boolean) {
		this.#hovered = hovered;
		this.#updateMaterial();
	}
	/** @returns whether the current side is hovered */
	get hovered(): boolean {
		return this.#hovered;
	}

	#selected: boolean = false;
	/** whether the current side is selected */
	set selected(selected: boolean) {
		this.#selected = selected;
		this.#updateMaterial();
	}
	/** @returns whether the current side is selected */
	get selected(): boolean {
		return this.#selected;
	}

	#updateMaterial(): void {
		if (this.selected || this.hovered) {
			this.material.side = DoubleSide;
		} else {
			this.material.side = FrontSide;
		}

		if (this.selected) {
			this.material.color.set(this.selectedColor);
		} else {
			this.material.color.set(this.color);
		}

		if (this.hovered) {
			this.material.opacity = 0.6;
		} else {
			this.material.opacity = 0.2;
		}
	}
}
