import { generateGUID } from "@faro-lotv/foundation";
import { GUID, ISOTimeString } from "@faro-lotv/ielement-types";

/** All the possible mutation types supported by this client implementation */
export enum MutationTypes {
  MutationSetFloorSectionSize = "MutationSetFloorSectionSize",
  MutationAddFloorLayout = "MutationAddFloorLayout",
  MutationSetFloorLayout = "MutationSetFloorLayout",
  MutationAddPointCloudStreamWebShare = "MutationAddPointCloudStreamWebShare",
  MutationAddPointCloud = "MutationAddPointCloud",
  MutationDeleteElement = "MutationDeleteElement",
  MutationDeleteVideoModeData = "MutationDeleteVideoModeData",
  MutationRemoveLabel = "MutationRemoveLabel",
  MutationSetElementName = "MutationSetElementName",
  MutationSetElementCreatedAt = "MutationSetElementCreatedAt",
  MutationSetElementDescription = "MutationSetElementDescription",
  MutationSetElementPose = "MutationSetElementPose",
  MutationSetElementPosition = "MutationSetElementPosition",
  MutationSetElementRotation = "MutationSetElementRotation",
  MutationSetElementScale = "MutationSetElementScale",
  MutationSetElementThumbnailUri = "MutationSetElementThumbnailUri",
  MutationExchangeFileUri = "MutationExchangeFileUri",
  MutationImportBimModel = "MutationImportBimModel",
  MutationConfirmVideoModeAlignment = "MutationConfirmVideoModeAlignment",
  MutationAddArea = "MutationAddArea",
  MutationAddImgSheet = "MutationAddImgSheet",
  MutationAddImg360ToRoom = "MutationAddImg360ToRoom",
  MutationAddLabel = "MutationAddLabel",
  MutationAddAllowedLabel = "MutationAddAllowedLabel",
  MutationAdd2dAttachment = "MutationAdd2dAttachment",
  MutationAdd3dNode = "MutationAdd3dNode",
  MutationSetDataSessionWorldPose = "MutationSetDataSessionWorldPose",
  MutationSetAreaWorldPose = "MutationSetAreaWorldPose",
  MutationUserDirectoryMarkupField = "MutationUserDirectoryMarkupField",
  MutationDropDownMarkupField = "MutationDropDownMarkupField",
  MutationDateTimeMarkupField = "MutationDateTimeMarkupField",
  MutationAddMarkup = "MutationAddMarkup",
  MutationAddExternalMarkup = "MutationAddExternalMarkup",
  MutationAddMarkupField = "MutationAddMarkupField",
  MutationAddMarkupPolygon = "MutationAddMarkupPolygon",
  MutationAddMeasurePolygon = "MutationAddMeasurePolygon",
  MutationAddAttachment = "MutationAddAttachment",
  MutationMoveDataSession = "MutationMoveDataSession",
  MutationSetMetaData = "MutationSetMetaData",
  MutationSetElementRefCoordSystemMatrix = "MutationSetElementRefCoordSystemMatrix",
  MutationAddPhotogrammetryInput = "MutationAddPhotogrammetryInput",
  MutationAddVolume = "MutationAddVolume",
}

/**
 * Properties common to all mutations.
 */
export interface BaseMutation {
  /** The id is only used to reference this mutation in the response and should be unique within a given request. */
  id: GUID;

  /** The type of the mutation to apply */
  type: MutationTypes;

  /** ID of the IElement to mutate */
  elementId?: GUID;

  /**
   * Represents the timestamp at which this mutation has been created by the client.
   * Should be in date-time string using ISO 8601
   */
  createdAt: ISOTimeString;
}

/**
 * @returns true if it is a valid BaseMutation
 * @param data object to check
 */
export function isBaseMutation(data: unknown): data is BaseMutation {
  if (!data || typeof data !== "object") return false;

  const toCheck: Partial<BaseMutation> = data;

  return (
    typeof toCheck.id === "string" &&
    typeof toCheck.createdAt === "string" &&
    Object.values<unknown>(MutationTypes).includes(toCheck.type) &&
    (!toCheck.elementId || typeof toCheck.elementId === "string")
  );
}

/**
 * @returns a valid base mutation with a unique id and the creation date
 * @param type the type of the mutation
 * @param elementId this mutation will mutate
 */
export function createBaseMutation<
  Type extends MutationTypes,
  ID extends GUID | undefined,
>(type: Type, elementId: ID): BaseMutation & { type: Type; elementId: ID } {
  return {
    id: generateGUID(),
    type,
    elementId,
    createdAt: new Date().toISOString(),
  };
}
