import { TypedEvent } from "@faro-lotv/foundation";
import { Box3, Intersection, Object3D, Plane, Raycaster, Vector3 } from "three";

/** Distinct modes of rendering that the CadModel object may have */
export enum CadRenderingMode {
	/** Renders only the transparent subscene of the CAD assembly graph */
	TransparentScene = 0,
	/** Renders only the opaque subscene of the CAD assembly graph */
	OpaqueScene = 1,
	/** Renders the IDs of the CAD parts. */
	PartsIDs = 2,
}

/**
 * Interface for a CAD model
 */
export interface ICadModel extends Object3D {
	/** Whether this custom object renders its opaque subscene, its transparent subscene, or the parts IDs. */
	renderingMode: CadRenderingMode;

	/** Defines whether this Cad model supports clipping. */
	clipping: boolean;

	/** User-defined clipping planes. */
	clippingPlanes: Plane[];

	/** Changes the behavior of clipping planes so that only their intersection is clipped, rather than their union. */
	clipIntersection: boolean;

	/** Whether the clipping planes are in CAD's local transform or not. */
	clippingInLocalTransform: boolean;

	/** The CAD part highlighting color */
	highlightingColor: Vector3;

	/**
	 * Returns the ObjectIDs of the CAD model.
	 *
	 * @returns The ObjectIDs of the CAD model.
	 */
	get objectIds(): IterableIterator<number>;

	/**
	 * Sets whether CAD object is highlighted or not
	 *
	 * @param objectId Id of the CAD object
	 * @param v True iff object should be highlighted.
	 */
	setObjectHighlighted(objectId: number, v: boolean): void;

	/**
	 * Returns whether a CAD object is highlighted or not
	 *
	 * @param objectId Id of the CAD object
	 * @returns whether the CAD object is highlighted or not
	 */
	isObjectHighlighted(objectId: number): boolean;

	/**
	 * Sets whether a CAD object is visible or not
	 *
	 * @param objectId Id of the CAD object
	 * @param v Whether n should be visible or not
	 */
	setObjectVisible(objectId: number, v: boolean): void;

	/**
	 * Returns whether a CAD object is visible or not
	 *
	 * @param objectId Id of the CAD object
	 * @returns whether the CAD object is visible or not
	 */
	isObjectVisible(objectId: number): boolean;

	/**
	 * Event emitted when the visible objects changes
	 *
	 * @returns The event object
	 */
	get visibleObjectsChanged(): TypedEvent<void>;

	/**
	 * Given a drawId code that identified a CAD mesh in GPU, returns the
	 * corresponding CAD object id
	 *
	 * @param drawId The drawId read from GPU
	 * @returns the ID of the CAD object with the specific drawId, undefined.
	 */
	drawIdToObjectId(drawId: number): number | undefined;

	/**
	 * Returns the number of (leaf) mesh nodes in the CAD scene graph.
	 *
	 * @returns The number of mesh nodes in the CAD assembly graph.
	 */
	nodesCount(): number;

	/**
	 * Raycasts a single node of the model.
	 *
	 * @param n index of the node argument of raycasting
	 * @param raycaster Raycaster object
	 * @param intersections List of found intersections
	 */
	raycastNode(n: number, raycaster: Raycaster, intersections: Array<Intersection<Object3D>>): void;

	/**
	 * Returns the bounding box for the n-th node of the CAD scene graph,
	 * in world coordinates
	 *
	 * @param n The index of the queried node
	 * @param ret The resulting bounding box.
	 * @returns The world bounding box of the queries node.
	 */
	nodeBoundingBox(n: number, ret: Box3): Box3;

	/**
	 * Set the model to be sutiable for tomographic rendering, see TomographicModelPass.
	 *
	 * @param tomographic set to true to enable tomographic rendering
	 */
	setTomographic(tomographic: boolean): void;
}
