import { TypedEvent } from "@faro-lotv/foundation";
import { Camera, Group, Matrix4, Object3D, Plane, Quaternion, Raycaster, Vector2, Vector3 } from "three";
import { memberWithPrivateData } from "../../Utils";
import { TouchEvents } from "../TouchEvents";
import { BoxGizmo, BoxGizmoColors, BoxGizmoSide, BoxHandle } from "./BoxGizmo";
import { BoxRotationGizmo, RotationHandle } from "./BoxRotationGizmo";
import { BoxSide } from "./BoxSide";
import { BoxTranslationGizmo, TranslationHandle } from "./BoxTranslationGizmo";
import { GizmoHandle, GizmoHandleState } from "./GizmoHandle";

/** Name to assign to the parent object of the controls scene graph */
export const BOX_CONTROLS_NAME = "TheBoxControls";

export type TransformSpace = "local" | "world";

export type BoxControlsInteractionType = "translate" | "rotate" | "scale" | "selectSide";

type BoxControlsProps = {
	/* The camera to use for clicking */
	camera: Camera;
	/* The element to listen for events */
	element: HTMLElement;
	/* Initial box size */
	size?: Vector3;
	/* Initial box position */
	position?: Vector3;
	/* Initial box rotation */
	rotation?: Quaternion;
	/* Colors to use for the box controls. Defaults to a faro blue. */
	colors?: Partial<BoxGizmoColors>;
	/* The size of the box handles in pixels*/
	handleSize?: number;
	/** The up direction for the gizmos */
	up?: Vector3;
};

/**
 * A class to place and edit on the fly a box in a scene.
 * Useful to select a specific area
 *
 * @beta this component could still change it's api
 */
export class BoxControls extends Object3D {
	// The camera to use for clicking
	#camera: Camera;
	//  The element to listen for events
	#element?: HTMLElement;
	// Handling touch events
	#touchEvents = new TouchEvents();

	// Group used to hold all the child objects
	#group: Group;

	// The gizmos for manipulating the box
	#boxGizmo: BoxGizmo;
	#translationGizmo: BoxTranslationGizmo;
	#rotationGizmo: BoxRotationGizmo;

	#space: TransformSpace;

	// The box planes
	#clippingPlanes = new Array<Plane>();

	/** The mouse coordinates of the mouseDown event, or undefined, if the mouse has been released */
	#mouseDown: [number, number] | undefined;
	#currentHandle: GizmoHandle | undefined;
	#hoveredHandle: GizmoHandle | undefined;
	#startPoint: Vector3 | undefined;

	#raycaster = new Raycaster();
	#mouseClip = new Vector2();

	/** Event for when this control has started being interacted with */
	interactStarted = new TypedEvent<void>();
	/** Event for when this control has stopped being interacted with */
	interactionStopped = new TypedEvent<void>();
	/** Event indicating which interaction would be executed, if the user were to click. Use e.g. to change the cursor. */
	hoveredInteractionTypeChanged = new TypedEvent<BoxControlsInteractionType | undefined>();
	/** Event for when if clipping has been changed between inside and outside */
	clipInsideChanged = new TypedEvent<boolean>();
	/** Event for when the clipping planes change */
	clippingPlanesChanged = new TypedEvent<Plane[]>();
	/** Callback when the user selects a side */
	sideSelected = new TypedEvent<BoxGizmoSide>();

	#enableTranslateX = true;
	/** @returns true if you can translate on X axis */
	get enableTranslateX(): boolean {
		return this.#enableTranslateX;
	}
	/** true if you can translate on X axis */
	set enableTranslateX(val: boolean) {
		this.#enableTranslateX = val;
		this.showAxes();
	}

	#enableTranslateY = true;
	/** @returns true if you can translate on Y axis */
	get enableTranslateY(): boolean {
		return this.#enableTranslateY;
	}
	/** true if you can translate on Y axis */
	set enableTranslateY(val: boolean) {
		this.#enableTranslateY = val;
		this.showAxes();
	}

	#enableTranslateZ = true;
	/** @returns true if you can translate on Z axis */
	get enableTranslateZ(): boolean {
		return this.#enableTranslateZ;
	}
	/** true if you can translate on Z axis */
	set enableTranslateZ(val: boolean) {
		this.#enableTranslateZ = val;
		this.showAxes();
	}

	#enableRotateX = true;
	/** @returns true if you can rotate on X axis */
	get enableRotateX(): boolean {
		return this.#enableRotateX;
	}
	/** true if you can rotate on X axis */
	set enableRotateX(val: boolean) {
		this.#enableRotateX = val;
		this.showAxes();
	}

	#enableRotateY = true;
	/** @returns true if you can rotate on Y axis */
	get enableRotateY(): boolean {
		return this.#enableRotateY;
	}
	/** true if you can rotate on Y axis */
	set enableRotateY(val: boolean) {
		this.#enableRotateY = val;
		this.showAxes();
	}

	#enableRotateZ = true;
	/** @returns true if you can rotate on Z axis */
	get enableRotateZ(): boolean {
		return this.#enableRotateZ;
	}
	/** true if you can rotate on Z axis */
	set enableRotateZ(val: boolean) {
		this.#enableRotateZ = val;
		this.showAxes();
	}

	#enableScaleX = true;
	/** @returns true if you can scale on X axis */
	get enableScaleX(): boolean {
		return this.#enableScaleX;
	}
	/** true if you can scale on X axis */
	set enableScaleX(val: boolean) {
		this.#enableScaleX = val;
		this.showAxes();
	}

	#enableScaleY = true;
	/** @returns true if you can scale on Y axis */
	get enableScaleY(): boolean {
		return this.#enableScaleY;
	}
	/** true if you can scale on Y axis */
	set enableScaleY(val: boolean) {
		this.#enableScaleY = val;
		this.showAxes();
	}

	#enableScaleZ = true;
	/** @returns true if you can scale on Z axis */
	get enableScaleZ(): boolean {
		return this.#enableScaleZ;
	}
	/** true if you can scale on Z axis */
	set enableScaleZ(val: boolean) {
		this.#enableScaleZ = val;
		this.showAxes();
	}

	#selectedSide = BoxGizmoSide.zPositive;
	/** @returns the last selected side */
	get selectedSide(): BoxGizmoSide {
		return this.#selectedSide;
	}
	/** the last selected side */
	set selectedSide(val: BoxGizmoSide) {
		this.#selectedSide = val;
		this.#updateSideSelection();

		this.sideSelected.emit(val);
	}

	#enableSideSelection = false;
	/** @returns true if the user can select a side by clicking on it */
	get enableSideSelection(): boolean {
		return this.#enableSideSelection;
	}
	/** true if the user can select a side by clicking on it */
	set enableSideSelection(val: boolean) {
		this.#enableSideSelection = val;
		this.#updateSideSelection();
	}

	/** Updates the visuals to show the selected side */
	#updateSideSelection(): void {
		this.#boxGizmo.selectedSide = this.#enableSideSelection ? this.#selectedSide : undefined;
	}

	#clipInside = true;
	/** @returns true to clip the inside of the box */
	get clipInside(): boolean {
		return this.#clipInside;
	}
	/** true to clip the inside of the box */
	set clipInside(value: boolean) {
		if (value !== this.#clipInside) {
			this.#clipInside = value;
			this.recomputeBox();
			this.clipInsideChanged.emit(value);
		}
	}

	/**
	 *	Constructs the box controls.
	 */
	constructor({
		camera,
		element,
		size = new Vector3(1, 1, 1),
		position,
		rotation,
		colors,
		handleSize = 10,
		up = new Vector3(0, 1, 0),
	}: BoxControlsProps) {
		super();
		this.name = BOX_CONTROLS_NAME;
		this.#camera = camera;
		this.#element = element;
		this.#handleSize = handleSize;

		this.#group = new Group();
		this.add(this.#group);

		this.#space = "local";

		this.#boxGizmo = new BoxGizmo(element, camera, colors);
		this.#group.add(this.#boxGizmo);

		this.#translationGizmo = new BoxTranslationGizmo(element, camera, this.#space);
		this.#group.add(this.#translationGizmo);

		this.#rotationGizmo = new BoxRotationGizmo(element, camera, this.#space);
		this.#group.add(this.#rotationGizmo);

		for (const pp of this.#boxGizmo.handles) {
			this.#clippingPlanes.push(new Plane().setFromNormalAndCoplanarPoint(pp.axis, pp.position));
		}

		// This is the default up of the application (and threejs)
		// If we will ever decide to switch to Z-up, we will need to change this
		const UP_AXIS = Object3D.DEFAULT_UP;
		const matrix = new Matrix4();
		const rotationAxis = new Vector3().crossVectors(up, UP_AXIS).normalize();
		if (rotationAxis.length() > Number.EPSILON) {
			// Check how much the gizmos should rotate around the axis to have the correct
			// up direction
			matrix.makeRotationAxis(rotationAxis, Math.acos(up.dot(UP_AXIS)));
		} else if (up.dot(UP_AXIS) > 0) {
			// This case the up axis is equal to UP_AXIS
			matrix.identity();
		} else {
			// This case the up axis points in the opposite direction of UP_AXIS
			matrix.makeRotationAxis(new Vector3(1, 0, 0), Math.PI);
		}
		this.#boxGizmo.quaternion.setFromRotationMatrix(matrix);
		this.#translationGizmo.quaternion.setFromRotationMatrix(matrix);
		this.#rotationGizmo.quaternion.setFromRotationMatrix(matrix);

		if (position) this.#group.position.copy(position);
		if (rotation) this.#group.quaternion.copy(rotation);
		this.#group.scale.copy(size);

		this.updateMatrixWorld();
		this.recomputeBox();

		this.onMouseDown = this.onMouseDown.bind(this);
		this.onMouseUp = this.onMouseUp.bind(this);
		this.onMouseDrag = this.onMouseDrag.bind(this);
		this.onMouseMove = this.onMouseMove.bind(this);

		// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- FIXME
		if (element) {
			this.attachElement(element);
		}

		this.#touchEvents.mousePressed.on(this.onMouseDown);
		this.#touchEvents.mouseReleased.on(this.onMouseUp);
		this.#touchEvents.mouseDragged.on(this.onMouseDrag);
		this.#touchEvents.mouseMoved.on(this.onMouseMove);
		this.traverse((o) => (o.renderOrder = 10));
	}

	/** Disabling the default raycasting on this object. */
	override raycast(): void {}

	/**
	 * Dispose all resources and event connections
	 */
	dispose(): void {
		this.detachElement();
		this.#touchEvents.mousePressed.off(this.onMouseDown);
		this.#touchEvents.mouseReleased.off(this.onMouseUp);
		this.#touchEvents.mouseDragged.off(this.onMouseDrag);
		this.#touchEvents.mouseMoved.off(this.onMouseMove);
	}

	/** Enable/Disable this control class */
	set enabled(e: boolean) {
		this.#touchEvents.enabled = e;
	}

	/** @returns true if the controls are enabled */
	get enabled(): boolean {
		return this.#touchEvents.enabled;
	}

	/**
	 * Attach this controls to an element to receive events from
	 *
	 * @param target The element to listen for events
	 */
	attachElement(target: HTMLElement): void {
		this.#touchEvents.attach(target);
	}

	/**
	 * If attached to an element, detach all event listeners
	 */
	detachElement(): void {
		this.#touchEvents.detach();
	}

	/** @returns the box clipping planes */
	getClippingPlanes(): Plane[] {
		return this.#clippingPlanes;
	}

	/**
	 * Compute the clipping coordinates from the touchEvents mouse coords
	 *
	 * @returns The x/y coordinates in clipping space [-1, 1]
	 */
	mouseToClip(): Vector2 {
		if (!this.#element) throw new Error("Box Controls not attached");
		const mouse = this.#touchEvents.mouseCoords.position;
		this.#mouseClip.x = (mouse.x / this.#element.clientWidth) * 2 - 1;
		this.#mouseClip.y = 1 - (mouse.y / this.#element.clientHeight) * 2;
		return this.#mouseClip;
	}

	/**
	 * Handle mouse down
	 *
	 * @param ev The mouse down event
	 */
	private onMouseDown(ev: MouseEvent): void {
		if (ev.button === 0) {
			this.#mouseDown = [ev.clientX, ev.clientY];
			const clip = this.mouseToClip();
			this.#raycaster.setFromCamera(clip, this.#camera);
			const hits = this.#raycaster.intersectObjects<BoxHandle | TranslationHandle | RotationHandle>([
				...this.#boxGizmo.handles.filter((pp) => pp.visible),
				...this.#translationGizmo.handles.filter((pp) => pp.visible),
				...this.#rotationGizmo.handles.filter((pp) => pp.visible),
			]);
			if (hits.length > 0) {
				hits.sort((a, b) => a.distance - b.distance);
				this.#currentHandle = hits[0].object;
				this.#startPoint = hits[0].point;
				this.#currentHandle.state = GizmoHandleState.focused;
				this.interactStarted.emit();
			}
		}
	}

	/**
	 * Handle mouse up
	 *
	 * @param ev The mouse up event
	 */
	private onMouseUp(ev: MouseEvent): void {
		const isClick =
			this.#mouseDown &&
			Math.max(Math.abs(ev.clientX - this.#mouseDown[0]), Math.abs(ev.clientY - this.#mouseDown[1])) < 2;

		if (ev.button === 0) {
			this.#mouseDown = undefined;
			if (this.#currentHandle) {
				this.#currentHandle.state = GizmoHandleState.default;
				this.#currentHandle = undefined;
				this.#startPoint = undefined;
				this.interactionStopped.emit();
			} else if (this.#enableSideSelection && isClick) {
				const sideHits = this.#raycaster.intersectObjects<BoxSide>([
					...this.#boxGizmo.sides.filter((pp) => pp.visible),
				]);

				if (sideHits.length) {
					this.selectedSide = this.#boxGizmo.getSideForObject(sideHits[0].object);
					this.changeHoveredSide(undefined);
				}
			}
		}
	}

	/** Handle mouse drag */
	onMouseDrag(): void {
		if (this.#currentHandle instanceof BoxHandle && this.#startPoint) {
			this.#raycaster.setFromCamera(this.mouseToClip(), this.#camera);
			this.#currentHandle.onMouseDrag(this.#group, this.#startPoint, this.#raycaster);
			this.recomputeBox();
		} else if (this.#currentHandle instanceof TranslationHandle && this.#startPoint) {
			this.#raycaster.setFromCamera(this.mouseToClip(), this.#camera);
			this.#currentHandle.onMouseDrag(this.#group, this.#startPoint, this.#raycaster, this.#space);
			this.recomputeBox();
		} else if (this.#currentHandle instanceof RotationHandle && this.#startPoint) {
			this.#raycaster.setFromCamera(this.mouseToClip(), this.#camera);
			this.#currentHandle.onMouseDrag(this.#group, this.#startPoint, this.#raycaster, this.#space);
			this.recomputeBox();
		}
	}

	/** Handle mouse move */
	onMouseMove(): void {
		if (!this.#mouseDown) {
			let hoveredInteractionType: BoxControlsInteractionType | undefined;

			const clip = this.mouseToClip();
			this.#raycaster.setFromCamera(clip, this.#camera);
			const hits = this.#raycaster.intersectObjects<BoxHandle | TranslationHandle | RotationHandle>([
				...this.#boxGizmo.handles.filter((pp) => pp.visible),
				...this.#translationGizmo.handles.filter((pp) => pp.visible),
				...this.#rotationGizmo.handles.filter((pp) => pp.visible),
			]);

			let hoveredHandle: GizmoHandle | undefined;
			let hoveredSide: BoxSide | undefined;

			if (hits.length > 0) {
				hits.sort((a, b) => a.distance - b.distance);
				const h = hits[0].object;

				if (h instanceof BoxHandle) {
					hoveredInteractionType = "scale";
					if (!this.#enableSideSelection) {
						hoveredSide = this.#boxGizmo.handlesToSideMap.get(h);
					}
				} else if (h instanceof TranslationHandle) {
					hoveredInteractionType = "translate";
				} else if (h instanceof RotationHandle) {
					hoveredInteractionType = "rotate";
				}

				hoveredHandle = h;
			} else if (this.#enableSideSelection) {
				const sideHits = this.#raycaster.intersectObjects<BoxSide>([
					...this.#boxGizmo.sides.filter((pp) => pp.visible),
				]);

				if (sideHits.length && sideHits[0].object !== this.#boxGizmo.sidesMap.get(this.#selectedSide)) {
					hoveredInteractionType = "selectSide";

					hoveredSide = sideHits[0].object;
				}
			}

			this.changeHoveredHandle(hoveredHandle);
			this.changeHoveredSide(hoveredSide);

			this.hoveredInteractionTypeChanged.emit(hoveredInteractionType);
		}
	}

	/**
	 * Changes the currently hovered handle and updates the visuals
	 *
	 * @param hovered The BoxHandle to show the hover effect on
	 */
	private changeHoveredHandle(hovered: GizmoHandle | undefined): void {
		if (this.#hoveredHandle === hovered) return;
		if (this.#hoveredHandle?.state === GizmoHandleState.hovered) {
			this.#hoveredHandle.state = GizmoHandleState.default;
		}
		this.#hoveredHandle = hovered;
		if (this.#hoveredHandle?.state === GizmoHandleState.default) {
			this.#hoveredHandle.state = GizmoHandleState.hovered;
		}
	}

	#hoveredSide: BoxSide | undefined;
	private changeHoveredSide(hovered: BoxSide | undefined): void {
		if (this.#hoveredSide === hovered) return;
		if (this.#hoveredSide) {
			this.#hoveredSide.hovered = false;
		}
		this.#hoveredSide = hovered;
		if (this.#hoveredSide) {
			this.#hoveredSide.hovered = true;
		}
	}

	/**
	 * Updates all the clipping planes to align with the box.
	 */
	private recomputeBox = memberWithPrivateData(() => {
		const tmp = new Vector3();
		// The world position of the bounding box center
		const gp = new Vector3();
		// The world position of any of the six box handles
		const ppw = new Vector3();

		return (): void => {
			if (!this.#group.parent) return;

			this.#group.getWorldPosition(tmp);
			gp.copy(tmp);
			for (let index = 0; index < this.#clippingPlanes.length; index++) {
				const plane = this.#clippingPlanes[index];
				const pp = this.#boxGizmo.handles[index];

				pp.getWorldPosition(ppw);
				const vec = tmp.subVectors(ppw, gp).normalize();
				if (!this.#clipInside) vec.negate();
				plane.setFromNormalAndCoplanarPoint(vec, ppw);
			}
			this.clippingPlanesChanged.emit(this.#clippingPlanes);
		};
	});

	/** Updates which axes are shown given the visibility settings and current mode */
	private showAxes(): void {
		this.#rotationGizmo.showX = this.enableRotateX;
		this.#rotationGizmo.showY = this.enableRotateY;
		this.#rotationGizmo.showZ = this.enableRotateZ;

		this.#translationGizmo.showX = this.enableTranslateX;
		this.#translationGizmo.showY = this.enableTranslateY;
		this.#translationGizmo.showZ = this.enableTranslateZ;
	}

	/** Set if the controls work in 3D world coordinates or in the local coordinates of the group */
	set space(space: TransformSpace) {
		this.#space = space;
		this.#translationGizmo.space = space;
		this.#rotationGizmo.space = space;
	}
	/** @returns The transform space, "world" or "local" */
	get space(): TransformSpace {
		return this.#space;
	}
	/** @returns the size of the box in meters */
	get size(): Vector3 {
		return this.#group.scale;
	}
	/** Sets the size of the box in meters */
	set size(size: Vector3) {
		this.#group.scale.copy(size);
		this.recomputeBox();
	}
	/** @returns the position of the center of the box in world space */
	get pos(): Vector3 {
		return this.#group.position;
	}
	/** Sets the position of the center of the box in world space */
	set pos(pos: Vector3) {
		this.#group.position.copy(pos);
		this.recomputeBox();
	}

	/** @returns the rotation of the bounding box */
	get rot(): Quaternion {
		return this.#group.quaternion;
	}

	/** @returns the world matrix of the user defined box */
	get boxMatrixWorld(): Matrix4 {
		return this.#group.matrixWorld;
	}

	#handleSize: number;
	/** @returns The scale of the handles relative to the size of the face they are on */
	get handleSize(): number {
		return this.#handleSize;
	}
	/** Sets the scale of the handles relative to the size of the face they are on */
	set handleSize(scale: number) {
		this.#handleSize = scale;
		this.recomputeBox();
	}
	/** Toggle the axis control between "local" and "world" space */
	toggleLocalWorldSpace(): void {
		this.space = this.space === "local" ? "world" : "local";
	}
}
